<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form">
      <a-row>
        <a-col>
          <a-form-model-item label="用戶昵稱">
            {{ form.userName }}
          </a-form-model-item>
        </a-col>
        <a-col>
          <a-form-model-item label="举报的用戶昵稱">
            {{ form.toUserName }}
          </a-form-model-item>
        </a-col>
        <a-col>
          <a-form-model-item label="刷手號" prop="userNum">
            {{ form.userNum }}
          </a-form-model-item>
        </a-col>
        <a-col>
          <a-form-model-item label="類型">
            {{ form.typeName }}
          </a-form-model-item>
        </a-col>
        <a-col>
          <a-form-model-item label="举报理由">
            {{ form.content }}
          </a-form-model-item>
        </a-col>
        <a-col>
          <a-form-model-item label="举报内容">
            {{ form.resourceData }}
          </a-form-model-item>
        </a-col>
        <a-col>
        <a-form-model-item label="舉報图片" prop="avatar">
         <span  v-if="form.imgUrls != null && form.imgUrls != ''">
           <img @click="imgShow(imgUrl)" style="width: 100px;height: 100px;margin-left: 5px" preview="舉報图片" v-for="(imgUrl, index) in form.imgUrls.split(',')" :src="imgUrl" v-if="form.imgUrls != null && form.imgUrls != ''"/>
          </span>
          <span v-else>
            暂无图片
          </span>
        </a-form-model-item>
        </a-col>
        <a-col>
          <a-form-model-item label="举报類型">
            <span v-if="form.resourceType == 1">視頻</span>
            <span v-if="form.resourceType == 2">評論</span>
            <span v-if="form.resourceType == 3">用戶</span>
            <span v-if="form.resourceType == 4">赛事</span>
            <span v-if="form.resourceType == 5">直播</span>
          </a-form-model-item>
        </a-col>
        <a-col>
          <a-form-model-item label="视频" v-if="form.resourceType == 1 &&form.video">
            <div class="imgbox margin-center" @click="showDetail(form.video)">
              <div class="videomask">
                <a-icon class="icon" type="play-circle" />
              </div>
              <img  style="width: 48px;height: 48px;"  :src="form.video + '?x-oss-process=video/snapshot,t_30000,f_jpg,w_0,h_0,m_fast,ar_auto'" alt=""
                    class="response">
            </div>
          </a-form-model-item>
        </a-col>
        <a-col>
          <a-form-model-item label="直播間" v-if="form.resourceType == 5">
              <a-button  type="primary" @click="$refs.showDirect.getList(form.resourceId)" v-if=" form.resourceType == 5">
                所屬直播間
              </a-button>
          </a-form-model-item>
        </a-col>

        <a-col>
          <a-form-model-item label="处理狀態">
            <span v-if="form.processStatus == 1">待处理</span>
            <span v-if="form.processStatus == 2">已处理</span>
            <span v-if="form.processStatus == 3">暂不处理</span>
          </a-form-model-item>
        </a-col>
        <a-col>
          <a-form-model-item label="处理结果">
            {{ form.processResult }}
          </a-form-model-item>
        </a-col>

        <a-col>
          <a-form-model-item label="舉報時間">
            <span>{{ form.createTime }}</span>
          </a-form-model-item>
        </a-col>
      </a-row>


      <div class="bottom-control">
        <a-space>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
    <!-- 視頻预览播放 -->
    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel" :width="600" :height="'100%'"
             style="top: 0; padding: 0;">
      <!-- <img alt="example" style="width: 100%" :src="previewImage" /> -->
      <video  :src="previewImage" ref="myVideo" autoPlay  width="560" height="600"
              :poster=" previewImage + '?x-oss-process=video/snapshot,t_0,f_jpg'" :controls="true"></video>
    </a-modal>

    <modal-image-preview :image-url="currentImageUrl" v-if="currentImageUrl" />

    <show-direct ref="showDirect" />
  </a-drawer>
</template>

<script>
import {getUserReport} from '@/api/platform/userReport'
import Video from '@/views/video/modules/Video'
import ModalImagePreview from '@/components/ModalImagePreview/index'
import ShowDirect from "@/views/platform/userReport/modules/ShowDirect"
import {imgShow} from "@/utils/ruoyi";

export default {
  name: 'Details',
  props: {},
  components: {
    Video,
    ModalImagePreview,
    ShowDirect


  },
  data() {
    return {
      currentImageUrl:null,
      submitLoading: false,
      formTitle: '',
      previewVisible: false,
      previewImage: '',
      // 表单参数
      form: {
        id: null,

        mobile: null,

        password: null,

        avatar: null,

        nickname: null,

        sex: null,

        birthday: null,

        userSign: null,

        isNotice: null,

        status: 0,

        createTime: null,

        remark: null,

        email: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {}
    }
  },
  filters: {},
  created() {
  },
  computed: {},
  watch: {},
  mounted() {
  },
  methods: {
    imgShow(imgUrl){
      imgShow(imgUrl)

    },
    showDetail(video){
      this.previewImage = video;
      this.previewVisible = true;
    },
    handleCancel() {
      this.previewImage = ''
      this.previewVisible = false
    },
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        id: null,
        mobile: null,
        password: null,
        avatar: null,
        nickname: null,
        sex: null,
        birthday: null,
        userSign: null,
        isNotice: null,
        status: 0,
        createTime: null,
        remark: null,
        email: null
      }
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      // getUserReport({"id":id}).then(response => {
      //   this.form = response.data
      //   this.open = true
      //   this.formTitle = '详情'
      // })
      this.form = row
      this.open = true
      this.formTitle = '详情'
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateUser(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addUser(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.imgbox {
  overflow: hidden;
  background-color: #f7f7f7;
  position: relative;
  cursor: pointer;

  width: 50px;
  height: 50px;
  margin: 0 4px 4px 0;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

.videomask {
  position: absolute;
  width: 50px;
  height: 50px;
  margin: 0 auto;
  line-height: 55px;
  background: rgba(0, 0, 0, .3);
  text-align: center;

  .icon {
    display: inline-block;
    font-size: 18px !important;
    color: #fff !important;
  }
}
</style>