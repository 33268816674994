<template>
  <div v-if="isVisible" class="modal-overlay" @click="hideModal">
    <div class="modal-content" @click.stop>
      <img :src="imageUrl" alt="Image Preview" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    imageUrl: String
  },
  data() {
    return {
      isVisible: false
    };
  },
  methods: {
    showModal() {
      this.isVisible = true;
    },
    hideModal() {
      this.isVisible = false;
    }
  }
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 20px;
}

img {
  max-width: 100%;
  max-height: 100%;
}
</style>