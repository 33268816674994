import request from '@/utils/request'


// 查询用户举报业务列表
export function listUserReport(query) {
  return request({
    url: '/platform/user-report/list',
    method: 'get',
    params: query
  })
}

// 查询用户举报业务分页
export function pageUserReport(query) {
  return request({
    url: '/platform/user-report/page',
    method: 'get',
    params: query
  })
}

// 查询用户举报业务详细
export function getUserReport(data) {
  return request({
    url: '/platform/user-report/detail',
    method: 'get',
    params: data
  })
}

// 新增用户举报业务
export function addUserReport(data) {
  return request({
    url: '/platform/user-report/add',
    method: 'post',
    data: data
  })
}

// 修改用户举报业务
export function updateUserReport(data) {
  return request({
    url: '/platform/user-report/edit',
    method: 'post',
    data: data
  })
}

// 删除用户举报业务
export function delUserReport(data) {
  return request({
    url: '/platform/user-report/delete',
    method: 'post',
    data: data
  })
}
